var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"moonpay-currency-table-container"},[_c('CCard',{staticClass:"moonpay-currency-list-table-card"},[_c('CDataTable',{attrs:{"items":_vm.items,"fields":_vm.fields,"fixed":"","hover":"","striped":"","bordered":""},scopedSlots:_vm._u([{key:"platform",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm._f("truncate")(item.platform,20))+" ")])]}},{key:"symbol",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm._f("truncate")(item.symbol,20))+" ")])]}},{key:"name",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm._f("truncate")(item.name,20))+" ")])]}},{key:"icon",fn:function(ref){
var item = ref.item;
return [_c('td',[(item.icon)?_c('img',{staticClass:"rounded currency-image",attrs:{"src":item.icon}}):_vm._e()])]}},{key:"status_text",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('StakingBadge',{attrs:{"type":"exchange-currency-status","text":item.status_text}})],1)]}},{key:"ms_status_text",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('StakingBadge',{attrs:{"type":"exchange-currency-status","text":item.ms_status_text}})],1)]}},{key:"type",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm._f("truncate")(item.type,20))+" ")])]}},{key:"action",fn:function(ref){
var item = ref.item;
return [_c('td',{attrs:{"width":"50px"}},[_c('button',{staticClass:"btn btn-view-details",on:{"click":function($event){return _vm.viewDetails(item)}}},[_c('img',{staticClass:"hand edit-icon",attrs:{"src":require("@/assets/images/ico-edit-user.svg"),"alt":""}})])])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }