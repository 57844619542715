<template>
  <div class="moonpay-currency-list">
    <CCard>
      <CCardHeader>
        <CRow>
          <CCol md="3" sm="12">
            <h1>{{$t("MOONPAY_CURRENCY_LIST_PAGE_TITLE")}}</h1>
          </CCol>
          <CCol md="6" sm="12"></CCol>
          <CCol md="3" sm="12">
            <CButton
              class="btn btn-success btn-add-new"
              @click="addNewMoonpayCurrency()"
            >+ {{$t("MOONPAY_CURRENCY_LIST_PAGE_ADD_NEW")}}</CButton>
          </CCol>
        </CRow>
      </CCardHeader>
      <CCardBody>
        <SearchMoonpayCurrencyHeader
          :onSearch="onSearch"
          :platformList="platformList"
          :statuses="statuses"
        />

        <MoonpayCurrencyListTable
          :limit="limit"
          :activePage="activePage"
          :items="items"
          :total="total"
        />
        <StakingPagination
          :limit="limit"
          :total="total"
          :activePage="activePage"
          @changePage="onChangePage"
          @changePageSize="onChangePageSize"
        />
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import MoonpayCurrencyListTable from '@/components/MoonpayCurrencies/MoonpayCurrencyListTable';
import { endpoints, env, Constants } from '@/constants';
import responseHelper from '@/utils/response-helper';
import stringHelper from '@/utils/string-helper';
import StakingPagination from '@/components/StakingPagination';
import SearchMoonpayCurrencyHeader from '@/components/MoonpayCurrencies/SearchMoonpayCurrencyHeader';
export default {
  name: 'MoonpayCurrencyList',
  components: {
    MoonpayCurrencyListTable,
    StakingPagination,
    SearchMoonpayCurrencyHeader
  },
  data() {
    return {
      items: [],
      platformList: [],
      statuses: [],
      filterData: {},
      total: 0,
      limit: env.app.pageSize,
      activePage: 1,
      dateFormat: Constants.dateFormat,
    };
  },
  computed: {
    offset() {
      return (this.activePage - 1) * this.limit;
    },
    pages() {
      return Math.ceil(this.total / this.limit);
    },
  },
  async mounted() {
    await Promise.all([this.getPlatforms(), this.getMoonpayCurrencyStatuses()]);
    await this.getMoonpayCurrencies();
  },
  methods: {
    async getPlatforms() {
      try {
        const result = await this.$http.get(endpoints.getMoonpayPlatforms);
        console.log(result.data);
        this.platformList = result.data.map(item => {
          return {
            value: item.platform,
            label: item.platform,
          };
        });
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('MOONPAY_CURRENCY_LIST_NOTIFICATION_ERROR_TITLE'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
    async getMoonpayCurrencyStatuses() {
      try {
        const result = await this.$http.get(endpoints.getMoonpayCurrencyStatuses);

        this.statuses = stringHelper.translateTextForDropdownList(result.data, 'MOONPAY_CURRENCY_STATUS');
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('MOONPAY_CURRENCY_LIST_NOTIFICATION_ERROR_TITLE'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
    async getMoonpayCurrencies(data) {
      data = data || {};
      this.filterData = {
        ...this.filterData,
        ...data,
      };
      const filterData = this.filterData;

      try {
        const params = {
          limit: this.limit,
          offset: this.offset,
          name: filterData.name,
          symbol: filterData.symbol,
          platform: filterData.platform,
          status: filterData.status,
          ms_status: filterData.ms_status,
        };
        const result = await this.$http.get(endpoints.getMoonpayCurrencies, { params });

        this.items = result.data.items || [];
        this.total = result.data.total;
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('MOONPAY_CURRENCY_LIST_PAGE_TITLE_NOTIFY_ERROR'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
    async onChangePage(page) {
      this.activePage = page;

      this.getMoonpayCurrencies();
    },
    async onChangePageSize(value) {
      this.limit = value;
      this.activePage = 1;

      this.getMoonpayCurrencies();
    },
    async onSearch(searchData) {
      const data = searchData;
      this.activePage = 1;

      this.getMoonpayCurrencies(data);
    },
    addNewMoonpayCurrency() {
      this.$router.push('moonpay-currencies/create');
    },
  },
};
</script>

<style lang="scss">
.moonpay-currency-list {
  padding: 0 24px;
  @media screen and (max-width: 1024px) {
    padding: 0 15px;
  }

  .card {
    > .card-header {
      border-bottom: none;
      padding-bottom: 0px;

      h1 {
        color: #657187;
        font-size: 18px;
        font-weight: 600;
        line-height: 34px;
        margin-bottom: 0px;
      }

      .btn-add-new {
        border: none;
        border-radius: 32px;
        height: 32px;
        padding: 0 30px;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 14px;
        text-align: center;
        border-radius: 17.5px;
        min-width: 175px;
        display: block;
        letter-spacing: 0.5px;
        line-height: 16px;
        text-align: center;
        color: #fff;
        border: 1px solid #1a2958;
        border-radius: 17.5px;
        background: #3b4b7e;
        float: right;
      }
    }
  }
}
</style>
